import React from 'react'
import { StaticQuery } from 'gatsby'
import _ from 'lodash'
import Layout from '../../components/layout'
import PageFooter from '../../components/PageFooter'
import Image from '../../components/Image'
import Timeline from '../../components/Timeline'
import Faqs from '../../components/Faqs'

import mapMarkdownRemarks, { findAllEntriesWithTags } from '../../components/mapMarkdownRemarks'
import withAdminLinks from '../../utils/withAdminLinks';

import { Row, Col } from 'react-flexbox-grid'
import { localize } from '../../utils/localize'


function Div(props) {
  return <div className={props.className} style={props.style}>
    {props.children}
  </div>
}

const DivWithAdminLinks = withAdminLinks(Div);

function MenuItem({ item, index }) {
  const image = _.get(item, 'images[0]', false)
  return (
    <Col
      node={item}
      tagName="a"
      role="button"
      xs={12}
      sm={6}
      style={{ marginBottom: 16, color: 'hsla(0,0%,0%,0.9)' }}
      href={`#${index}`}
    >
      <Row start="xs" className="flex-center__y">
        <Col xs={2}>
          <Image
            style={{ backgroundColor: 'transparent' }}
            mode="fit"
            width={50}
            height={50}
            src={image}
          />
        </Col>
        <Col xs={10} style={{ paddingLeft: 16 }}>
          <h4 style={{ marginTop: 0, marginBottom: 0 }}>{item.title}</h4>
          <div dangerouslySetInnerHTML={{ __html: item.shortExcerpt }} />
        </Col>
      </Row>
    </Col>
  )
}

const ScholarshipPage = ({
  menuItems,
  menuItemsNode,
  faqEntries,
  faqEntriesNode,
  vision,
  eligibility,
  timeline,
  timelineSteps,
  language,
}) => {

  return (
    <Layout language={language}>
      <h1 className="text-center">{
        localize({
          language,
          en: "“Les Elites De Demain” scholarship",
          fr: "Bourse “Les Elites De Demain”"
        })} 
      </h1>
      <DivWithAdminLinks node={menuItemsNode} className="container" style={{ paddingTop: 50 }}>
        <Row center="xs">
          <Col xs={12} md={9}>
            <Row>
              {menuItems.map((item, index) => (
                <MenuItem key={`${index}`} item={item} index={index} />
              ))}
            </Row>
          </Col>
        </Row>
      </DivWithAdminLinks>

      <DivWithAdminLinks node={vision} className="section" style={{}}>
        <div className="container">
          <Row center="xs">
            <Col xs={12} sm={9}>
              <a name="0" style={{ visibility: 'hidden' }} />
              <h2 className="title">{vision.title}</h2>
              <div
                className="md-content"
                dangerouslySetInnerHTML={{ __html: vision.html }}
              />
            </Col>
          </Row>
        </div>
      </DivWithAdminLinks>
      <DivWithAdminLinks node={eligibility} className="section" style={{}}>
        <div className="container">
          <Row center="xs">
            <Col xs={12} sm={9}>
              <a name="1" style={{ visibility: 'hidden' }} />
              <h2 className="title">{eligibility.title}</h2>
              <div
                className="md-content"
                dangerouslySetInnerHTML={{ __html: eligibility.html }}
              />
            </Col>
          </Row>
        </div>
      </DivWithAdminLinks>
      <DivWithAdminLinks className="container">
        <div className="section" style={{ paddingTop: 0, paddingBottom: 0 }}>
          <a name="2" style={{ visibility: 'hidden' }} />
          <h2 className="title text-center">{timeline.title}</h2>
          <Row center="xs">
            <Col xs={12} sm={9}>
              <Timeline node={timeline} steps={timelineSteps} />
            </Col>
          </Row>
        </div>
      </DivWithAdminLinks>
      <DivWithAdminLinks node={faqEntriesNode} className="container" style={{ paddingTop: 0 }}>
        <div className="section">
          <a name="3" style={{ visibility: 'hidden' }} />
          <h2 className="title text-center" style={{ marginBottom: 24 }}>
            {faqEntriesNode.title}
          </h2>
          <Row center="xs">
            <Col xs={12} sm={9}>
              <Faqs
                entries={faqEntries.map(entry => ({
                  question: entry.title,
                  answer: entry.shortExcerpt,
                }))}
              />
            </Col>
          </Row>
        </div>
      </DivWithAdminLinks>
      <PageFooter language={language} />
    </Layout>
  )
}

const ScholarshipPageQuery = props => (
  <StaticQuery
    query={graphql`
      query {
        menuItems: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#scholarship-menu-items" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        vision: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#scholarship-vision" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        eligibility: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#scholarship-eligibitliy" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        timeline: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#scolarship-timeline" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }

        faqEntries: allMarkdownRemark(
          filter: { frontmatter: { tags: { in: "#scholarship-faq-entries" } } }
        ) {
          edges {
            node {
              ...AllMarkdownRemarkDataFragment
            }
          }
        }
      }
    `}
    render={(data) => {
      const { pageContext } = props;
      const language = _.get(pageContext, 'language')
      const menuItems = _.first(mapMarkdownRemarks(data.menuItems.edges || [], language))

      const faqEntries = _.first(mapMarkdownRemarks(
        data.faqEntries.edges || [],
        language
      ))
      const vision = _.first(
        mapMarkdownRemarks(data.vision.edges || [], language)
      )
      const eligibility = _.first(
        mapMarkdownRemarks(data.eligibility.edges || [], language)
      )

      const timelineNode = _.first(
        mapMarkdownRemarks(data.timeline.edges || [], language)
      )

      return (
        <ScholarshipPage
          language={language}
          menuItems={menuItems.entries}
          menuItemsNode={menuItems}
          faqEntries={faqEntries.entries}
          faqEntriesNode={faqEntries}
          vision={vision}
          timeline={timelineNode}
          timelineSteps={findAllEntriesWithTags(timelineNode, ['#timeline-item'])}
          eligibility={eligibility}
          {...props}
        />
      )
    }}
  />
)

export default ScholarshipPageQuery
